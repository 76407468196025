export interface LoadingStatus {
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

export interface LoadingStatusConfig {
  default: LoadingStatus;
  loading: LoadingStatus;
  loaded: LoadingStatus;
  error: LoadingStatus;
}

export const LOADING_STATUS: LoadingStatusConfig = {
  default: {loading: false, loaded: false, error: false},
  loading: {loading: true, loaded: false, error: false},
  loaded: {loading: false, loaded: true, error: false},
  error: {loading: false, loaded: true, error: true},
};
